:root {
  --font-size-heading-primary: 62px;
  --font-size-heading-primary-mobile: 44px;
  --font-weight-heading-primary: 800;
  --font-weight-heading-secondary: var(--font-weight-heading-primary);
  --font-size-heading-secondary: 42px;
  --font-size-heading-secondary-mobile: 28px;
  --font-size-subhead-primary: 24px;
  --font-weight-body-2: 400;
  --line-height-body-2: 140%;
  --font-size-body-2: 22px;
}

@mixin body2 {
  font-size: var(--font-size-body-2, 22px);
  font-weight: var(--font-weight-body-2, 400);
  line-height: var(--line-height-body-2, 140%);
}
