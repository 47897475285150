@import 'colors.scss';
@import 'text.scss';

:root {
  --header-height-responsive: 76px;
  --profile-menu-padding-inline: 1.2rem;
  --main-content-width: 1060px;

  font-family: Averta;
}
