:root {
  /* palette */
  --color-sky-blue: #b0bcf3;
  --color-purple-400: #6a1099;
  --color-cool-purple-100: #ecefff;
  --color-cool-purple-600: #330b66;
  --color-cool-purple-700: #34258d;
  --color-gradient-primary: #798dd3;
  --color-gradient-secondary: #a763cb;
  --color-gray-400: #525157;

  /* theme */
  --color-background-primary: var(--color-sky-blue);
  --color-background-button-primary: var(--color-cool-purple-700);
  --color-text-button-primary: #ffffff;
  --color-text-primary: var(--color-cool-purple-700);
  --color-text-alternate: var(--color-gray-400);
}
